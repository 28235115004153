import React from 'react';
import { Link } from 'gatsby';
import Layout from 'components/layout';
import SEO from 'components/seo';

// markup
const CaseStudy = ({ pageContext }) => (
  <Layout>
    <SEO
      title={pageContext.caseStudy.metaTitle}
      description={pageContext.caseStudy.metaDescription}
    />
    <div>
      <div
        className="bg-secondary"
        style={{
          backgroundImage: 'linear-gradient(to right, #efcecc, white, #efcecc)',
        }}
      >
        <div className="container px-0 md:px-4">
          <div className="bg-secondary--2 p-2 uppercase text-base  mb-12 lg:block">
            <nav>
              <ul className="flex flex-col md:flex-row w-full md:w-min">
                {pageContext.navItems.map((item) => (
                  <li
                    key={item.label}
                    className="mb-2 md:mb-0 border-secondary border-r-2 whitespace-nowrap"
                  >
                    <Link
                      activeClassName="font-semibold"
                      className="px-7  py-2 "
                      to={item.href}
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="h-1 " />
        </div>
      </div>
      <div className="my-12 container">
        <img
          src="/images/cs-1.jpg"
          alt=""
          width="1600"
          height="600"
          className="object-cover h-auto max-h-96"
        />
      </div>
      <div className="container max-w-5xl pb-16 text-primary">
        <article className="post">
          <h1 className="mb-4">{pageContext.caseStudy.title}</h1>

          <h5 className="h5">
            <span className="font-semibold">Client: </span>
            {pageContext.caseStudy.client}
          </h5>
          <h5 className="h5">
            <span className="font-semibold">Need: </span>
            {pageContext.caseStudy.need}
          </h5>
          <h5 className="h5 mb-8">
            <span className="font-semibold ">Maistro service: </span>
            {pageContext.caseStudy.service}
          </h5>

          <h5 className="mb-8">Challenge</h5>
          {pageContext.caseStudy.challenge.map((challenge) => (
            <p key={challenge}>{challenge}</p>
          ))}

          <h5 className="mb-8 mt-12">What we did</h5>
          {pageContext.caseStudy.whatWeDid.map((whatWeDid) => (
            <p key={whatWeDid}>{whatWeDid}</p>
          ))}

          <h5 className="mb-8 mt-12">The result</h5>
          {pageContext.caseStudy.result.map((result) => (
            <p key={result}>{result}</p>
          ))}
          <ul className="list-disc pl-6">
            {pageContext.caseStudy.resultListItems.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </article>
      </div>
    </div>
  </Layout>
);

export default CaseStudy;
